<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline" :disabled="mergeMode">Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleMergeMode" type="text" icon="el-icon-share">Объединить</el-button>
      </el-col>
      <el-col v-if="userCanEdit && mergeMode" :span="3">
        <el-button @click="handleMergeModeCancel" type="text" icon="el-icon-circle-close">Отменить</el-button>
      </el-col>
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" />
      </el-col>
    </el-row>
    <div>
      <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
    </div>
    <div v-loading="loading" class="my-flex-data-content">

        <infinite-tree
          ref="tree"
          :data="primaryEquipments"
          :style="{'height' : '100%'}"
          @on-view-click="handleView"
          @on-check-click="handleCheck"
        >
          <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
        </infinite-tree>
    </div>
    <primary-equipment-modal @on-update="getPrimaryEquipments" @on-create="getPrimaryEquipments()" @on-delete="getPrimaryEquipments()"
                             v-model="dialogVisible" :primaryEquipment="dialogPrimaryEquipment"></primary-equipment-modal>
    <primary-equipments-merge-modal v-model="dialogMergeVisible" :items="mergingItems" @on-merge="handleMerge($event)" @on-merge-item-remove="handleMergeItemRemove($event)"></primary-equipments-merge-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import primaryEquipmentsApi from '@/api/nsi/primaryEquipments';
import reportsApi from '@/api/reports';
import io from '@/utilities/fileIO';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import pagination from '@/components/Ui/Pagination';
// import FactoryIcon from '@/assets/icons/factory.svg';
// import SettingIcon from '@/assets/icons/settings.svg';
// import SettingBoxIcon from '@/assets/icons/setting_box.svg';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';
import PrimaryEquipmentModal from './PrimaryEquipmentModal';
import PrimaryEquipmentsMergeModal from './PrimaryEquipmentsMergeModal';


export default {
  name: 'PrimaryEquipmentsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  components: {
    pagination,
    PrimaryEquipmentModal,
    infiniteTree,
    // FactoryIcon,
    // SettingIcon,
    // SettingBoxIcon,
    PrimaryEquipmentsMergeModal
    },
  mixins: [xlsUpDownHelper],
  data() {
    return {
      expand: false,
      loading: false,
      primaryEquipments: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogPrimaryEquipment: {
        primaryEquipmentTypeId: null,
        powerTransformerEndBaseVoltages: []
      },
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
      primaryEquipmentsCount: 0,
    };
  },
  watch: {
    filterModel: {
      handler() {
        // console.log('this.$refs.tree', this.$refs.tree);
        // this.$refs.tree.defaultExpandAll = true;
        // this.getPrimaryEquipments();
          this.debouncedGetData();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    treeContainer() {
        return this.$refs.tree;
    }
  },
  async created() {
    this.debouncedGetData = debounce(this.getPrimaryEquipments, 500);
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    await this.getPrimaryEquipments();
    // this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const params = { 
        pageNumber: this.pageNumber,
        pageSize: this.totalCount,
        nameFilter: this.filterModel.nameFilter,
        energyObjectIdFilter: this.filterModel.energyObjectIdFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        primaryEquipmentTypeIdFilter: this.filterModel.primaryEquipmentTypeIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        isRuFilter: this.filterModel.isRuFilter,
        hideLines: this.filterModel.hideLines,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter
      };
      const res = await reportsApi.createPrimaryEquipmentsReport(params);
      if (res.data.exceptionDetails) {
        this.$alert(`При формировании отчета произошла ошибка: ${res.data.exceptionDetails}`, 'Ошибка');
      } else {
        io.downloadFile(res.data.content, res.data.contentType, res.data.fileName);
      }
      // if (res.data) {
      //   await this.exportToExcelTree('Первичное оборудование/ЛЭП', res.data.items);
      // }
      this.loading = false;
    },
    async getPrimaryEquipments() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        energyObjectIdFilter: this.filterModel.energyObjectIdFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        primaryEquipmentTypeIdFilter: this.filterModel.primaryEquipmentTypeIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        isRuFilter: this.filterModel.isRuFilter,
        hideLines: this.filterModel.hideLines,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter
      };
      const res = await primaryEquipmentsApi.getPrimaryEquipmentsWithPagination(params);
      if (res.data) {
        this.primaryEquipments = res.data.items;
        this.totalCount = res.data.totalCount;
        this.primaryEquipmentsCount = res.data.treeItemsCount;
        this.defaultExpandedKeys = this.primaryEquipments.map((i) => i.baseId);

        this.treeContainer.tree.loadData(this.primaryEquipments);
        this.applyIsMerging(this.mergeMode);
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getPrimaryEquipments();
    },
    async handleCurrentChange() {
      await this.getPrimaryEquipments();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getPrimaryEquipments();
    },
    async handleView(node) {
      const res = await primaryEquipmentsApi.getPrimaryEquipmentItem(node.baseId);
      this.dialogPrimaryEquipment = res.data;
      this.dialogVisible = true;
    },
    // eslint-disable-next-line no-unused-vars
    handleCreate(index, primaryEquipment) {
        this.dialogPrimaryEquipment = {
          id: null,
          name: null,
          productAssetModelId: null,
          energyObjectIds: [],
          energyObjectIdsInit: [],
          // normallyInService: false,
          ownerOrganizations: [],
          technologicalAdministrationOrganizations: [],
          technologicalManagementOrganizations: [],
          dispatchAdministrationOrganizations: [],
          dispatchManagementOrganizations: [],
          isAip: false,
          primaryEquipmentTypeId: null,
          powerTransformerEndBaseVoltages: []
        };
        this.dialogVisible = true;
        this.$root.$emit('addEvent', {
          editMode: true,
        });
    },
    handleCheck(node) {
      if (node.state.checked) {
        this.mergingItems.push(node);

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'primaryEquipment' && t.baseId === node.baseId);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        const index = this.mergingItems.findIndex((x) => x.baseId === node.baseId);
        if (index > -1) {
          this.mergingItems.splice(index, 1);

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'primaryEquipment' && t.baseId === node.baseId);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }
    },
    applyIsMerging(isMerging) {
      const { nodes } = this.treeContainer.tree;
      nodes.forEach((node) => {
        if (node.nodeType === 'primaryEquipment') {
          if (isMerging && this.mergingItems.some((t) => t.baseId === node.baseId)) {
            node.state.checked = true;
          }

          if (!isMerging) node.state.checked = false;

          node.сheckAllowed = isMerging;
          node.viewAllowed = !isMerging;
        }
      });

      this.treeContainer.rerender();
    },
    // applyIsMerging() {
    //   for (let i = 0; i < this.primaryEquipments.length; i++) {
    //     if (this.primaryEquipments[i].children) {
    //       for (let j = 0; j < this.primaryEquipments[i].children.length; j++) {
    //         const item = this.primaryEquipments[i].children[j];
    //         this.$set(item, 'isMerging', this.mergingItems.some((x) => x.baseId === item.baseId));
    //       }
    //     }
    //   }
    // },
    handleMergeItemRemove(item) {
      const index = this.mergingItems.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.mergingItems.splice(index, 1);
        this.applyIsMerging(this.mergeMode);

        if (this.mergingItems.length <= 1) {
          this.dialogMergeVisible = false;
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        }
      }
    },
    async handleMerge(data) {
      if (data.secondaryItems.some((x) => x.isAip)) {
        this.$alert('Невозможно объединить несколько корпоративных записей или использовать корпоративную запись в качестве присоединяемой', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      this.loading = true;

      const mainItemId = data.mainItem.id.substr(data.mainItem.id.length - 36);

      const res = await primaryEquipmentsApi.mergePrimaryEquipments(mainItemId, data.secondaryItems.map((x) => x.baseId));
      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogMergeVisible = false;
        this.handleMergeModeCancel();
        await this.getPrimaryEquipments();
      }
      this.loading = false;
    },
    handleIsMergingChange(item) {
      if (!this.mergingItems.some((x) => x.id === item.baseId)) {
        this.mergingItems.push(item);
      } else {
        const index = this.mergingItems.findIndex((x) => x.baseId === item.baseId);
        if (index > -1) {
          this.mergingItems.splice(index, 1);
        }
      }
    },
    handleMergeMode() {
      if (!this.mergeMode) {
        this.mergeMode = true;
        this.applyIsMerging(this.mergeMode);
      } else {
        if (this.mergingItems.length <= 1) {
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.dialogMergeVisible = true;
        }
      }
    },
    handleMergeModeCancel() {
      this.mergingItems.splice(0);
      this.mergeMode = false;
      this.applyIsMerging(this.mergeMode);
    },
    toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyIsMerging(this.mergeMode);
    }
  }
};
</script>

<style scoped>
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
